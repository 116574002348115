import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card.js';
import { ExternalLink } from 'lucide-react';

const cheeseWebsites = [
  {
    name: "Wormhole Bridge",
    url: "https://bridge.cheesechain.xyz/wormhole",
    description: "A bridge from solana to cheese chain for cheese"
  },
  {
    name: "USDC Bridge",
    url: "https://bridge.cheesechain.xyz/",
    description: "A usdc stable coin bridge from arbitrum to cheese chain"
  },
  {
    name: "Wine",
    url: "https://chatover.wine/",
    description: "Wine perfect to pair with cheese"
  },
  {
    name: "Cheddex",
    url: "https://cheddex.xyz/",
    description: "A decentilized exchange for cheese chain"
  },
  {
    name: "Fetascan",
    url: "https://fetascan.xyz/",
    description: "A Cheese chain Blockchain Explorer"
  }

];

const CheeseWebsitesList = () => {
  return (
    <div className="min-h-screen p-4" style={{
      backgroundImage: `
        linear-gradient(30deg, #4c1d95 12%, transparent 12.5%, transparent 87%, #4c1d95 87.5%, #4c1d95),
        linear-gradient(150deg, #4c1d95 12%, transparent 12.5%, transparent 87%, #4c1d95 87.5%, #4c1d95),
        linear-gradient(30deg, #4c1d95 12%, transparent 12.5%, transparent 87%, #4c1d95 87.5%, #4c1d95),
        linear-gradient(150deg, #4c1d95 12%, transparent 12.5%, transparent 87%, #4c1d95 87.5%, #4c1d95),
        linear-gradient(60deg, #5b21b6 25%, transparent 25.5%, transparent 75%, #5b21b6 75%, #5b21b6),
        linear-gradient(60deg, #5b21b6 25%, transparent 25.5%, transparent 75%, #5b21b6 75%, #5b21b6)
      `,
      backgroundSize: '80px 140px',
      backgroundPosition: '0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px'
    }}>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-yellow-300">Cheese Chain Websites Directory</h1>
        <div className="grid gap-4 md:grid-cols-2">
          {cheeseWebsites.map((site, index) => (
            <Card key={index}>
              <CardHeader>
                <CardTitle className="flex items-center justify-between">
                  {site.name}
                  <a href={site.url} target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-900">
                    <ExternalLink size={20} />
                  </a>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>{site.description}</CardDescription>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheeseWebsitesList;